<template>
  <div class="container">
    <h1>Запросы</h1>
    <div class="mb-3" v-if="allOrders.length > 0">
      <div class="text-right mb-4">
        <b-button-group class="m-2" size="sm">
          <b-button
            title="Отобразить плитками"
            @click.prevent="showType = 'type1'"
            :disabled="showType == 'type1'"
          >
            <b-icon icon="grid-fill"></b-icon>
          </b-button>
          <b-button
            title="Отобразить списком"
            @click.prevent="showType = 'type2'"
            :disabled="showType == 'type2'"
          >
            <b-icon icon="list"></b-icon>
          </b-button>
          <b-button
            title="Отобразить плитками по 4"
            @click.prevent="showType = 'type3'"
            :disabled="showType == 'type3'"
          >
            <b-icon icon="grid3x3-gap-fill"></b-icon>
          </b-button>
        </b-button-group>
      </div>
      <div class="row mb-4" v-if="showType == 'type1'">
        <div
          class="col-md-6"
          v-for="(item, index) in allOrders"
          v-bind:key="index"
        >
          <div class="order-item">
            <div class="row">
              <div class="col-auto" v-if="item['vehicle']">
                <div class="order-item__image" v-if="item['vehicle']['photo']">
                  <b-link :to="'/orders/' + item._id + '/vehicle'">
                    <img
                      :src="
                        'https://vp.bw2api.ru/' +
                        item['vehicle']['photo']['url']
                      "
                      alt=""
                      class="img-fluid"
                    />
                  </b-link>
                </div>
              </div>
              <div class="col">
                <b-link
                  :to="'/orders/' + item._id + '/vehicle'"
                  class="order-item__car-name"
                  v-if="item['vehicle']"
                >
                  {{ item["vehicle"]["brand"] }} {{ item["vehicle"]["model"] }}
                  {{ item["vehicle"]["year"] }}
                </b-link>
                <div class="order-item__text">
                  {{ item.text }}
                </div>
                <b-button
                  variant="primary"
                  :to="'/orders/' + item._id"
                  class="order-item__btn"
                  >Детали заказа</b-button
                >
                <div class="order-item__created">
                  Создан: {{ item.createdAt | moment("DD.MM.YY H:mm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4" v-if="showType == 'type2'">
        <div
          class="col-12"
          v-for="(item, index) in allOrders"
          v-bind:key="index"
        >
          <div class="order-item">
            <div class="row">
              <div class="col-auto" v-if="item['vehicle']">
                <div class="order-item__image" v-if="item['vehicle']['photo']">
                  <b-link :to="'/orders/' + item._id + '/vehicle'">
                    <img
                      :src="
                        'https://vp.bw2api.ru/' +
                        item['vehicle']['photo']['url']
                      "
                      alt=""
                      class="img-fluid"
                    />
                  </b-link>
                </div>
              </div>
              <div class="col">
                <b-link
                  :to="'/orders/' + item._id + '/vehicle'"
                  class="order-item__car-name"
                  v-if="item['vehicle']"
                >
                  {{ item["vehicle"]["brand"] }} {{ item["vehicle"]["model"] }}
                  {{ item["vehicle"]["year"] }}
                </b-link>
                <div class="order-item__text">
                  {{ item.text }}
                </div>
                <b-button
                  variant="primary"
                  :to="'/orders/' + item._id"
                  class="order-item__btn"
                  >Детали заказа</b-button
                >
                <div class="order-item__created">
                  Создан: {{ item.createdAt | moment("DD.MM.YY H:mm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4" v-if="showType == 'type3'">
        <div
          class="col-md-4 col-lg-3"
          v-for="(item, index) in allOrders"
          v-bind:key="index"
        >
          <div class="order-item3">
            <div class="row">
              <div class="col-12" v-if="item['vehicle']">
                <div class="order-item3__image" v-if="item['vehicle']['photo']">
                  <b-link :to="'/orders/' + item._id + '/vehicle'">
                    <img
                      :src="
                        'https://vp.bw2api.ru/' +
                        item['vehicle']['photo']['url']
                      "
                      alt=""
                      class="img-fluid mb-3"
                    />
                  </b-link>
                </div>
              </div>
              <div class="col-12">
                <b-link
                  :to="'/orders/' + item._id + '/vehicle'"
                  class="order-item3__car-name"
                  v-if="item['vehicle']"
                >
                  {{ item["vehicle"]["brand"] }} {{ item["vehicle"]["model"] }}
                  {{ item["vehicle"]["year"] }}
                </b-link>
                <div class="order-item3__text">
                  {{ item.text }}
                </div>
                <b-button
                  variant="primary"
                  :to="'/orders/' + item._id"
                  class="order-item3__btn"
                  >Детали заказа</b-button
                >
                <div class="order-item3__created">
                  Создан: {{ item.createdAt | moment("DD.MM.YY H:mm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mb-5">
        <!-- <button
          class="btn btn-primary"
          @click="showMore"
          v-if="allOrders.length > countShow"
        >
          Показать ещё
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "orders",
  data() {
    return {
      // countShow: 10,
      showType: "type1",
    };
  },
  computed: {
    ...mapGetters(["allOrders", "profile", "allOrdersCount"]),

    // ordersShow() {
    //   return this.allOrders.slice(0, this.countShow);
    // },
  },
  components: {},
  methods: {
    // showMore() {
    //   this.countShow += 10;
    // },
  },
};
</script>
